'use strict';

// Constructor
var HeaderDefault = function() {
  $( document ).ready(function() {
    console.log('ready!')
    var hamburger = $('#hamburger-icon');
    hamburger.click(function() {
      hamburger.toggleClass('active');
      $('.menu').toggleClass('visible');
      return false;
    });
  });
};

module.exports = HeaderDefault;
